import { useCallback } from 'react'

import { ColumnsType } from 'antd/es/table'
import { computed, runInAction } from 'mobx'
import { OrderMode, ViewColumnTemplateFragment, ViewDataRowFragment } from 'types/graphql'

import { SortInput } from 'src/components/TableV2/types'
import TableViewCell from 'src/components/TableViews/TableViewCell'
import { TableViewManager } from 'src/models/TableViews/TableViewManager'

export const useTableViewsAntdConfig = (args: {
  columnTemplates: ViewColumnTemplateFragment[]
  tableViewManager: TableViewManager
}) => {
  const { columnTemplates, tableViewManager } = args
  const sort = computed(() => {
    const _sort = tableViewManager.selectedView?.sortManager.sorts[0]
    if (!_sort) {
      return undefined
    }
    return {
      fieldName: _sort.columnTemplateGeneratedId,
      mode: _sort.mode,
    }
  }).get()
  const sortInputs: SortInput[] = computed(
    () =>
      tableViewManager.selectedView?.filterManager.sortableColumnTemplates.map((ct) => ({
        columnId: ct.generatedId,
        fieldName: ct.generatedId,
      })) ?? []
  ).get()
  const columns: ColumnsType<ViewDataRowFragment> = computed(() => {
    if (!columnTemplates?.length) {
      return []
    }
    const viewColumns: ColumnsType<ViewDataRowFragment> =
      tableViewManager.selectedView?.columnManager.columns.map((c, index) => ({
        key: c.columnTemplate.generatedId,
        title: c.columnTemplate.labelTitleCase,
        sortOrder:
          c.columnTemplate.generatedId === sort?.fieldName
            ? sort.mode === 'Asc'
              ? 'ascend'
              : 'descend'
            : undefined,
        render: (row: ViewDataRowFragment) => (
          <TableViewCell row={row} data={row.columns[index] ?? []} />
        ),
      })) ?? []
    return viewColumns
  }).get()

  const onSortChanged = useCallback(
    (
      newSort:
        | {
            mode: string
            fieldName: string | number | symbol
          }
        | undefined
    ) => {
      runInAction(() => {
        if (!tableViewManager.selectedView) {
          return
        }
        if (!newSort) {
          tableViewManager.selectedView.sortManager.sorts = []
        } else {
          tableViewManager.selectedView.sortManager.sorts = [
            {
              columnTemplateGeneratedId: newSort.fieldName as string,
              mode: newSort.mode as OrderMode,
            },
          ]
        }
      })
    },
    [tableViewManager.selectedView]
  )

  return {
    columns,
    sort,
    sortInputs,
    onSortChanged,
  }
}
