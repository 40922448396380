import { Flex, Tag } from 'antd'
import { ViewDataElementFragment, ViewDataRowFragment } from 'types/graphql'

import { routes } from '@redwoodjs/router'

import { TableLink } from 'src/components/shared/StyledComponents'
import Text from 'src/components/Typography/Text'
import { useMyOrg, useOrgSlugMetadata } from 'src/layouts/AppStateContextLayout/utils'
import TableCellInspectionStatus from 'src/pages/RecordPage/Record/tabs/InspectionsTab/TableCellInspectionStatus'
import { RecordTypeStatusDisplay } from 'src/pages/RecordTypeStatusesPage/RecordTypeStatusesCell/RecordTypeStatusDisplay'
import { RecordTaskGroupTypeToRecordTaskGroupNameMap, formatName } from 'src/utils'
import { DateFormats, formatDateInTimeZone } from 'src/utils/date'

import { RecordTaskTypeDisplayMap } from '../../constants'
import { TransferTypeGovwellToDisplayMap } from '../../hooks/filter-inputs/use-payment-filter-inputs'
import { TaskStatusDisplay } from '../../pages/TasksPage/TaskStatusDisplay'
import { formatPaymentMethod } from '../../utils/fee'
import { FieldDisplay } from '../Field/FieldDisplay'
import RecordTaskPaymentStatusDisplay from '../RecordTaskPaymentStatusDisplay'
import { ViolationDisplay } from '../ViolationsCell/ViolationDisplay'

type Props = {
  data: ViewDataElementFragment[]
  row: ViewDataRowFragment
}
const TableViewCell = ({ data, row }: Props) => {
  return (
    <Flex wrap="wrap" gap="6px">
      {data.map((datum, index) => (
        <DataElement datum={datum} key={index} row={row} />
      ))}
    </Flex>
  )
}

const DataElement = ({
  datum,
  row,
}: {
  datum: ViewDataElementFragment
  row: ViewDataRowFragment
}) => {
  const org = useOrgSlugMetadata()
  const timeZone = org?.timeZone
  const paymentProviderFormatted = useMyOrg()?.paymentProviderFormatted || ''

  switch (datum.__typename) {
    case 'ViewDataElementBoolean':
      return <Text>{datum.bool === true ? 'True' : 'False'}</Text>
    case 'ViewDataElementDateTime':
      return (
        <Text>{formatDateInTimeZone(datum.dateTime, timeZone, DateFormats.MonthNameDateTime)}</Text>
      )
    case 'ViewDataElementRecord': {
      const record = datum.record
      return (
        <TableLink
          href={
            record.isDraft
              ? routes.editRecordDraft({
                  recordUuid: record.uuid,
                  slug: record.organization?.slug ?? '',
                })
              : routes.record({
                  recordUuid: record.uuid,
                })
          }
        >
          #{record.issuedIdentifier || record.identifier}
        </TableLink>
      )
    }
    case 'ViewDataElementRecordActionRequiredBy':
      return <Text>{datum.recordActionRequiredBy}</Text>
    case 'ViewDataElementRecordTaskGroupType':
      return <Text>{RecordTaskGroupTypeToRecordTaskGroupNameMap[datum.recordTaskGroupType]}</Text>
    case 'ViewDataElementRecordTemplate':
      return <Text>{datum.recordTemplate.name}</Text>
    case 'ViewDataElementRecordTypeStatus':
      return (
        <RecordTypeStatusDisplay
          isDraft={!datum.recordTypeStatus}
          status={datum.recordTypeStatus}
        />
      )
    case 'ViewDataElementString':
      return <Text>{datum.string}</Text>
    case 'ViewDataElementUser':
      return <Text>{datum.user ? formatName(datum.user.firstName, datum.user.lastName) : '-'}</Text>
    case 'ViewDataElementViolation': {
      return (
        <TableLink
          href={routes.codeComplaint({
            identifier: datum.violation.identifier,
          })}
        >
          #{datum.violation.identifier}
        </TableLink>
      )
    }
    case 'ViewDataElementViolationStatus': {
      return <ViolationDisplay status={datum.violationStatus} />
    }
    case 'ViewDataElementViolationType': {
      return <Tag>{datum.violationType.name}</Tag>
    }
    case 'ViewDataElementInspectionTemplate': {
      return datum.inspectionTemplate.name
    }
    case 'ViewDataElementRecordTaskStatus': {
      return <TaskStatusDisplay status={datum.recordTaskStatus} />
    }
    case 'ViewDataElementRecordTaskType': {
      return RecordTaskTypeDisplayMap[datum.recordTaskType]
    }
    case 'ViewDataElementRecordTaskInspectionResult': {
      if (!datum.recordTaskInspectionResult) {
        return '-'
      }
      return (
        <Tag color={datum.recordTaskInspectionResult.isPassing ? 'green' : 'red'}>
          {datum.recordTaskInspectionResult.name}
        </Tag>
      )
    }
    case 'ViewDataElementRecordTaskInspectionStatus': {
      // this is only being used for staff inspections right now, which by definition are all isAvailable=true
      // since staff can do whatever they want to inspections, whenever
      // in the future we should migrate the isAvailable logic to the backend
      // this logic currently lives in RecordPage/Record/tabs/InspectionsTab/util.ts
      if (!datum.recordTaskInspectionStatus || !row.recordTaskInspectionAttempt) {
        return '-'
      }
      return (
        <TableCellInspectionStatus
          attempt={row.recordTaskInspectionAttempt}
          inspectionCoordinationType={
            row.recordTaskInspectionAttempt?.recordTaskInspection?.inspectionGroup.record
              ?.organization?.inspectionCoordinationType ?? 'SchedulingRequest'
          }
          isAvailable
        />
      )
    }
    case 'ViewDataElementFeeType': {
      return datum.fee.name
    }
    case 'ViewDataElementFieldType': {
      return <FieldDisplay input={datum.field} />
    }
    case 'ViewDataElementGeneralLedgerNumberType': {
      return <Tag>{datum.generalLedgerNumber.glNumber}</Tag>
    }
    case 'ViewDataElementRecordTaskPaymentStatusType': {
      return <RecordTaskPaymentStatusDisplay status={datum.recordTaskPaymentStatus} />
    }
    case 'ViewDataElementRecordTypeType': {
      return datum.recordType.displayName
    }
    case 'ViewDataElementPaymentMethodType': {
      return formatPaymentMethod(datum.paymentMethod, paymentProviderFormatted)
    }
    case 'ViewDataElementPaymentTransferType': {
      return TransferTypeGovwellToDisplayMap[datum.paymentTransferType]
    }
    case 'ViewDataElementOrganizationFinixMerchantType': {
      return datum.organizationFinixMerchant.description
    }
  }
}

export default React.memo(TableViewCell)
