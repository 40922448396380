import { makeAutoObservable } from 'mobx'
import { ViewColumnFragment, ViewColumnTemplateFragment } from 'types/graphql'

export class TableViewColumn {
  public readonly columnTemplate: ViewColumnTemplateFragment
  public readonly columnTemplateGeneratedId: string
  public displayIndex: number

  constructor(args: {
    displayIndex: number
    columnTemplate: ViewColumnTemplateFragment
    columnGeneratedId: string
  }) {
    this.columnTemplate = args.columnTemplate
    this.columnTemplateGeneratedId = args.columnGeneratedId
    this.displayIndex = args.displayIndex
    makeAutoObservable(this)
  }

  get protocol(): ViewColumnFragment {
    return {
      columnTemplateGeneratedId: this.columnTemplateGeneratedId,
    }
  }
}
