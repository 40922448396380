import { makeAutoObservable, runInAction } from 'mobx'
import { ViewColumnTemplateFragment, ViewFilterFragment } from 'types/graphql'

import { TableColumnTemplate } from './TableColumnTemplate'
import { TableViewFilter } from './TableViewFilter'

export class FilterManager {
  public readonly columnTemplates: TableColumnTemplate[]

  private _filtersByColumnGeneratedId: Map<string, TableViewFilter> = new Map<
    string,
    TableViewFilter
  >()

  constructor(args: {
    filters: ViewFilterFragment[]
    columnTemplates: ViewColumnTemplateFragment[]
  }) {
    this.columnTemplates = runInAction(() =>
      args.columnTemplates
        .map((ct) => TableColumnTemplate.fromProtocol(ct))
        .sort((a, b) => a.labelSentenceCase.localeCompare(b.labelSentenceCase))
    )
    runInAction(() => {
      const filterEntries: [string, TableViewFilter][] = []
      args.filters.forEach((f) => {
        const columnTemplate = this.columnTemplates.find(
          (ct) => ct.generatedId === f.columnTemplateGeneratedId
        )
        if (!columnTemplate) {
          return // Should not happen
        }
        filterEntries.push([
          f.columnTemplateGeneratedId,
          new TableViewFilter({
            columnTemplate,
            protocol: { ...f, columnTemplateGeneratedId: f.columnTemplateGeneratedId },
          }),
        ])
      })
      this._filtersByColumnGeneratedId = new Map(filterEntries)

      // Make sure every column type is represented in the map, even if none were saved to the db because they were not populated
      this.columnTemplates.forEach((ct) => {
        if (!this._filtersByColumnGeneratedId.has(ct.generatedId)) {
          this._filtersByColumnGeneratedId.set(
            ct.generatedId,
            new TableViewFilter({
              columnTemplate: ct,
              protocol: {
                columnTemplateGeneratedId: ct.generatedId,
                bool: undefined,
                optionsSelected: undefined,
              },
            })
          )
        }
      })
    })
    makeAutoObservable(this)
  }

  public get filterableColumnTemplates(): TableColumnTemplate[] {
    return this.columnTemplates.filter((ct) => ct.isFilterable)
  }

  public get sortableColumnTemplates(): TableColumnTemplate[] {
    return this.columnTemplates.filter((ct) => ct.isSortable)
  }

  public get populatedFilters(): TableViewFilter[] {
    return this.filters.filter((f) => f.isPopulated)
  }

  public get filters(): TableViewFilter[] {
    return Array.from(this._filtersByColumnGeneratedId.values())
  }

  public get hasUnsavedChanges(): boolean {
    return Array.from(this._filtersByColumnGeneratedId.values()).some((f) => f.hasUnsavedChanges)
  }

  public clearAllFilters() {
    this._filtersByColumnGeneratedId = new Map<string, TableViewFilter>()
  }

  public getFilterByColumnGeneratedId(generatedId: string): TableViewFilter {
    const filter = this._filtersByColumnGeneratedId.get(generatedId)
    if (!filter) {
      throw new Error('Filter matching column type not found')
    }
    return filter
  }

  public reset() {
    this._filtersByColumnGeneratedId.forEach((f) => {
      f.reset()
    })
  }

  public save() {
    this._filtersByColumnGeneratedId.forEach((f) => {
      f.save()
    })
  }
}
