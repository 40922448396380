import { makeAutoObservable } from 'mobx'
import { ViewColumnFragment, ViewColumnTemplateFragment } from 'types/graphql'

import { TableViewColumn } from 'src/models/TableViews/TableViewColumn'

export class ColumnManager {
  public columns: TableViewColumn[] = []

  private _columnTemplates: ViewColumnTemplateFragment[] = []
  constructor(args: {
    columns: ViewColumnFragment[]
    columnTemplates: ViewColumnTemplateFragment[]
  }) {
    this._columnTemplates = args.columnTemplates
    const columns: TableViewColumn[] = []
    args.columns.forEach((c, displayIndex) => {
      const columnTemplate = args.columnTemplates.find(
        (ct) => ct.generatedId === c.columnTemplateGeneratedId
      )
      if (!columnTemplate) {
        return
      }
      columns.push(
        new TableViewColumn({
          columnTemplate,
          columnGeneratedId: c.columnTemplateGeneratedId,
          displayIndex,
        })
      )
    })
    this.columns = columns
    makeAutoObservable(this)
  }

  public get columnsByColumnGeneratedId(): Map<string, TableViewColumn> {
    return new Map(this.columns.map((c) => [c.columnTemplate.generatedId, c]))
  }

  public toggleColumnTypeVisibility(columnGeneratedId: string) {
    const index = this.columns.findIndex((c) => c.columnTemplate.generatedId === columnGeneratedId)
    if (index < 0) {
      const columnTemplate = this._columnTemplates.find(
        (ct) => ct.generatedId === columnGeneratedId
      )
      if (!columnTemplate) {
        return // Should not happen
      }
      this.columns.push(
        new TableViewColumn({
          columnTemplate,
          columnGeneratedId,
          displayIndex: this.columns.length,
        })
      )
    }
    // don't allow hiding last column
    else if (this.columns.length > 1) {
      this.columns.splice(index, 1)
    }
  }
}
